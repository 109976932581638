import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "flex gap-y-6 flex-col sm:flex-row flex-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "flex w-full"
}
const _hoisted_4 = { class: "flex flex-row ml-1 items-center mr-10" }
const _hoisted_5 = {
  key: 0,
  class: "bg-yellow-lp-200 rounded text-center text-yellow-lp-100 font-medium text-xs px-1"
}
const _hoisted_6 = {
  key: 1,
  class: "ml-1 bg-red-lp-600 rounded text-center text-red-lp-1600 font-medium text-xs px-1"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "mt-3 flex flex-row space-x-2" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "flex flex-row space-x-3 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ChipsV2 = _resolveComponent("ChipsV2")!
  const _component_ImagePreview = _resolveComponent("ImagePreview")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Title, {
        label: _ctx.$t('bookingShipment.column.itemDetails')
      }, null, 8, ["label"]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.column.commodity'),
          class: "w-6/12"
        }, {
          default: _withCtx(() => [
            (_ctx.detailData.sttCommodityName)
              ? (_openBlock(), _createBlock("div", _hoisted_3, [
                  _createVNode(_component_Chips, {
                    class: "my-2",
                    label: _ctx.detailData.sttCommodityName
                  }, null, 8, ["label"]),
                  _createVNode("div", _hoisted_4, [
                    (_ctx.detailData.sttCommodityIsQuarantine)
                      ? (_openBlock(), _createBlock("span", _hoisted_5, " K "))
                      : _createCommentVNode("", true),
                    (_ctx.detailData.isDangerousGoods)
                      ? (_openBlock(), _createBlock("span", _hoisted_6, " DG "))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_openBlock(), _createBlock("div", _hoisted_7, "-"))
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.serviceType'),
          class: "w-6/12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Chips, {
              class: "my-2",
              label: _ctx.detailData.sttProductTypeName
            }, null, 8, ["label"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.totalPieces'),
          value: _ctx.detailData.sttTotalPiece,
          class: "w-6/12"
        }, null, 8, ["label", "value"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.totalChargeableWeight'),
          value: 
          `${_ctx.convertDecimalWithComma(_ctx.detailData.sttTotalChargeAbleWeight)} Kg`
        ,
          class: "w-6/12"
        }, null, 8, ["label", "value"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.itemPriceEstimate'),
          value: 
          _ctx.formatPrice(
            _ctx.isSttForeign
              ? _ctx.detailData.sttGoodsEstimatePrice
              : _ctx.detailData.sttGoodsEstimatePriceForDetail
          )
        ,
          class: "w-6/12"
        }, null, 8, ["label", "value"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.shipmentInsurance'),
          class: "w-6/12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Chips, {
              class: "my-2",
              label: _ctx.detailData.sttInsuranceName.replace('Insurance ', '')
            }, null, 8, ["label"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.bookedBy'),
          value: _ctx.detailData.sttBookedBy,
          class: "w-6/12"
        }, null, 8, ["label", "value"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.billedTo'),
          value: _ctx.detailData.sttBilledTo,
          class: "w-6/12"
        }, null, 8, ["label", "value"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.source'),
          value: _ctx.detailData.sttSource,
          class: "w-6/12"
        }, null, 8, ["label", "value"]),
        (_ctx.accountIsNonForeign)
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 0,
              label: "COD Amount",
              value: _ctx.formatPrice(_ctx.detailData.sttCodAmountForDetail),
              class: "w-6/12"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.accountIsNonForeign)
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 1,
              label: "Tipe COD",
              class: "w-6/12",
              value: _ctx.typeCod
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.isEnableCodBooking && _ctx.accountIsNonForeign)
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 2,
              label: "Tipe COD",
              class: "w-6/12"
            }, {
              default: _withCtx(() => [
                _createVNode("div", _hoisted_8, [
                  (_ctx.detailData.sttCodType?.length)
                    ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.detailData.sttCodType, (item) => {
                        return (_openBlock(), _createBlock(_component_ChipsV2, {
                          key: item,
                          label: item,
                          title: item,
                          square: ""
                        }, null, 8, ["label", "title"]))
                      }), 128))
                    : (_openBlock(), _createBlock("span", _hoisted_9, "-"))
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.returnDO'),
          value: _ctx.detailData.sttReturnCod,
          class: "w-6/12"
        }, null, 8, ["label", "value"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('bookingShipment.detail.dimensions'),
          class: "w-6/12"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode("div", null, null, 512), [
              [_directive_sanitize_html, _ctx.getDimension]
            ])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('Packing Kayu'),
          class: "w-6/12",
          value: _ctx.detailData.sttIsWoodPacking ? 'Yes' : 'No'
        }, null, 8, ["label", "value"]),
        (_ctx.detailData.sttWeightAttachFiles.length > 1)
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 3,
              label: "Foto Pendukung Ubah Berat",
              class: "w-6/12"
            }, {
              default: _withCtx(() => [
                _createVNode("div", _hoisted_10, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.detailData.sttWeightAttachFiles, (photo) => {
                    return (_openBlock(), _createBlock("div", { key: photo }, [
                      photo
                        ? (_openBlock(), _createBlock("img", {
                            key: 0,
                            src: photo,
                            alt: "photo",
                            class: "rounded object-cover cursor-pointer",
                            style: {"height":"60px","width":"60px"},
                            onClick: () => _ctx.onPreview(photo)
                          }, null, 8, ["src", "onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_ImagePreview, {
      modelValue: _ctx.photoPreview,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.photoPreview = $event)),
      data: _ctx.photoPreviews,
      image: _ctx.photo,
      isTerm: false
    }, null, 8, ["modelValue", "data", "image"])
  ], 64))
}